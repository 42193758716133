import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const ArticleFooterBanners = () => (
  <StaticQuery
    query={graphql`
      query {
        articleFooterBannerWebinar:file(relativePath: {eq: "article-footer-banner-webinar.png"}) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <ul className="article__footer__list">
          <li className="article__footer__banner">
            <a href="https://my-ope-office.peatix.com/view" target="_blank" rel="noopener noreferrer">
              <Img alt="資生堂が取り組むAIチャットボット活用セミナー 申し込み" fluid={data.articleFooterBannerWebinar.childImageSharp.fluid} />
            </a>
          </li>
        </ul>
      )
    }}
  />
)

export default ArticleFooterBanners
